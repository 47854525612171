import gql from "graphql-tag";

export default gql`
  mutation SignInWithGoogleOAuthIdToken($idToken: String!) {
    signInWithGoogleOAuthIdToken(idToken: $idToken) {
      email
      isRecentlyCreatedUser
      accessToken
    }
  }
`;
