import GoogleOneTapSignIn from "components/auth/GoogleOneTapSignIn";
import Disclaimers from "components/landing/Disclaimers";
import classNames from "components/ui/classNames";
import * as React from "react";
import getBaseUrl from "../../lib/utils/getBaseUrl";
import Footer from "./Footer";
import Header from "./Header";

export default function Landing() {
  return (
    <div className="py-8 font-aeonik">
      <GoogleOneTapSignIn />

      <div className="mx-auto max-w-screen-legacyDesktop">
        <Header />
      </div>

      <main className="relative mt-8 grid grid-cols-1 gap-y-9">
        <section
          className={classNames(
            "relative before:absolute",
            "before:left-0 before:right-0 before:top-0 before:z-[-1] before:h-full before:w-full",
            "before:bg-[#2f4eda]",
            `pb-16 before:[clipPath:polygon(0_0,100%_0%,100%_100%,0_70%)] before:[content:""]`,
          )}
        >
          <div className="mx-auto grid max-w-screen-legacyDesktop grid-cols-1 justify-center gap-x-8 px-8 lg:grid-cols-12 xl:px-0">
            <div className="col-span-6 grid gap-y-7 py-9 text-center xl:text-left">
              <div className="grid grid-cols-6">
                <div className="col-span-6 xl:col-span-5">
                  <h1 className="m-0 text-xl font-black uppercase tracking-wider text-white xl:text-xl">
                    Análise Fundamentalista de Ações, Stocks e Fundos Imobiliários
                  </h1>
                </div>
              </div>

              <p className="m-0 text-sm tracking-wider text-white xl:text-base">
                Site completo com acesso imediato a mais de 5.000 ativos, indispensável para investidores que aplicam o
                Buy&Hold!
              </p>
            </div>

            <div className="relative col-span-6 hidden lg:block">
              <div className="absolute -top-6 left-0 w-full">
                <img
                  alt="Fundamentei"
                  draggable={false}
                  onContextMenu={(event) => event.preventDefault()}
                  onMouseDown={(event) => event.preventDefault()}
                  role="presentation"
                  src="/static/app-illustrations--screener.svg"
                  className="relative max-h-[364px] select-none"
                />
              </div>
            </div>
          </div>
        </section>

        <KeyFeatures />

        <WhoAreWe />

        <hr className="mx-auto w-full max-w-screen-legacyDesktop border-b-2 border-slate-200" />

        <Footer />

        <hr className="mx-auto w-full max-w-screen-legacyDesktop border-b-2 border-slate-200" />

        <Disclaimers />
      </main>
    </div>
  );
}

interface IKeyFeatureProps {
  feature: string;
  description: string;
}

function KeyFeature({ feature, description }: IKeyFeatureProps) {
  return (
    <div className="grid grid-cols-[auto-fit] gap-8 px-8 text-center lg:grid-cols-[max-content,1fr] lg:text-left xl:px-0">
      <div className="flex justify-center">
        <DuetoneCheckmark />
      </div>

      <div className="grid gap-y-3">
        <h5 className="m-0 text-sm font-bold uppercase tracking-wider text-[#4e657f]">{feature}</h5>

        <p className="m-0 text-sm tracking-wider text-[#4e657f]">{description}</p>
      </div>
    </div>
  );
}

function KeyFeatures() {
  return (
    <section className="mx-auto grid max-w-screen-legacyDesktop grid-cols-1 gap-y-9">
      <div>
        <h1 className="m-0 px-8 text-center text-lg font-bold uppercase tracking-wider text-[#4e657f] sm:text-xl lg:text-left xl:px-0">
          Quais são as vantagens?
        </h1>
      </div>

      <div className="grid grid-cols-2 justify-center gap-8 sm:grid-cols-12">
        <div className="col-span-6 grid gap-y-4">
          <ul className="m-0 grid list-none gap-y-9 p-0">
            <li>
              <KeyFeature
                feature="Análise Fundamentalista"
                description="Analise de forma rápida e objetiva os balanços de milhares de empresas e fundos imobiliários."
              />
            </li>

            <li>
              <KeyFeature
                feature="Análise Gráfica"
                description="Veja como as empresas desempenharam historicamente olhando as cotações mais antigas."
              />
            </li>

            <li>
              <KeyFeature
                feature="Análise em Vídeo"
                description="Vídeo exclusivo com a análise fundamentalista de diversas ações e fundos imobiliários."
              />
            </li>
          </ul>
        </div>

        <div className="ga-y-4 col-span-6 grid">
          <ul className="m-0 grid list-none gap-y-9 p-0">
            <li>
              <KeyFeature
                feature="Ranking de Ações, Stocks e FIIs"
                description="Veja quais são os melhores ativos de acordo com a avaliação dos usuários do site."
              />
            </li>

            <li>
              <KeyFeature
                feature="Dados das Empresas"
                description="Veja de forma simplificada o Código de Negociação, Atividade, Segmento de Listagem, Tag Along e Posição Acionária."
              />
            </li>

            <li>
              <KeyFeature
                feature="Dados dos Fundos Imobiliários"
                description="Veja de forma simplificada o tipo do fundo, a distribuição dos ativos, as taxas, os ativos e localização dos imóveis."
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

function DuetoneCheckmark(props: React.SVGProps<any>) {
  return (
    <svg width={37} height={28} {...props}>
      <g fill="none">
        <path
          fill="#2F4EDA"
          d="M36.46 5.864L19.166 23.443l-5.247-5.317L31.238.553a1.828 1.828 0 012.601-.01l.01.01 2.61 2.655a1.9 1.9 0 010 2.656z"
          opacity={0.4}
        />
        <path
          fill="#627BEA"
          d="M19.161 23.45l-3.935 4a1.825 1.825 0 01-2.601.01l-.01-.01L.613 15.247a1.899 1.899 0 010-2.655l2.611-2.655a1.824 1.824 0 012.596-.012l.012.012 13.33 13.512z"
        />
      </g>
    </svg>
  );
}

function WhoAreWe() {
  return (
    <section className="mx-auto grid max-w-screen-legacyDesktop grid-cols-1 justify-center gap-8 px-8 py-9 text-center sm:text-left lg:grid-cols-12">
      <div className="col-span-6">
        <div className="grid gap-y-4">
          <h1 className="m-0 text-base font-bold uppercase text-[#4e657f]">Sobre nós</h1>

          <p className="m-0 text-sm leading-relaxed tracking-wider text-[#4e657f] sm:text-base">
            Acreditamos que o Buy&Hold seja o método de investimento que mais se sustenta no longo prazo na Bolsa de
            Valores. O Fundamentei é a união dos nossos esforços para que você tenha em um único site o acesso a todas
            as informações que consideramos essenciais para você escolher os melhores ativos com foco no longo prazo.
          </p>
        </div>
      </div>

      <div className="col-span-6 grid gap-y-7">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-[max-content_1fr]">
          <div className="flex justify-center">
            <img
              src={`${getBaseUrl()}/static/people/eduardo-cavalcanti.png`}
              alt="Eduardo Cavalcanti"
              className="h-16 w-16 rounded-3xl object-cover"
              width={64}
              height={64}
            />
          </div>

          <div className="grid gap-y-2">
            <h4 className="m-0 text-base font-bold text-[#4e657f]">Eduardo Cavalcanti</h4>

            <p className="m-0 font-aeonik text-base italic text-slate-600">
              Pós-graduado em Finanças, Investimentos e Banking pela PUCRS, é especialista em Mercado de Capitais
              (ANBIMA), Consultor de Valores Mobiliários (CVM) e Analista de Valores Mobiliários (APIMEC). Criador do
              método Investidor Águia, exclusivo para investidores adeptos à filosofia Buy&Hold.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-[max-content_1fr]">
          <div className="flex justify-center">
            <img
              src={`${getBaseUrl()}/static/people/andrey.jpg`}
              alt="Andrey Vital"
              className="h-16 w-16 rounded-3xl object-cover"
              width={64}
              height={64}
            />
          </div>

          <div className="grid gap-y-2">
            <h4 className="m-0 text-base font-bold text-[#4e657f]">Andrey Vital</h4>

            <p className="m-0 font-aeonik text-base italic text-slate-600">
              Desenvolvedor de Software há mais de 9 anos. Trabalhou em empresas como Toptal, ClassDojo, DigitalRelab,
              VNA Group e atualmente está focado em criar o melhor site de análise fundamentalista do mundo.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
