import { LinkAnchorButton } from "lib/miolo";
import FundamenteiWithText from "components/branding/FundamenteiWithText";
import LogoSizingProps from "components/branding/LogoSizingProps";
import Link from "next/link";
import classNames from "components/ui/classNames";

export default function Header() {
  return (
    <header
      className={classNames(
        "grid max-w-screen-legacyDesktop grid-cols-1 items-center justify-center gap-8 px-8 text-center",
        "sm:grid-cols-[max-content_1fr] sm:text-left xl:px-0",
      )}
    >
      <Link href="/" title="Fundamentei">
        <FundamenteiWithText className="inline-flex" {...LogoSizingProps.FundamenteiWithText.VERY_SMALL} />
      </Link>

      <nav
        className={classNames(
          "grid grid-cols-[max-content] items-center justify-center gap-8",
          "sm:ml-auto sm:grid-cols-[repeat(2,max-content)]",
        )}
      >
        <div>
          <Link href="/login" legacyBehavior={true}>
            <LinkAnchorButton href="/login" className="text-base font-medium tracking-widest">
              Login
            </LinkAnchorButton>
          </Link>
        </div>

        <Link
          href="/sign-up"
          className={classNames(
            "m-0 box-border inline-block select-none overflow-visible rounded-[20px] border border-brand",
            "appearance-none px-4 py-1 text-base font-medium text-brand no-underline",
            "transition-all duration-75 ease-in-out",
            "hover:bg-brand hover:text-white",
            "focus:shadow-[0_0_0_3px_#d1d9fa] focus:outline-none",
            "active:shadow-transparent",
          )}
        >
          Cadastre-se
        </Link>
      </nav>
    </header>
  );
}
