import { gql } from "@apollo/client";

export default gql`
  query PortfolioResultsCenter {
    portfolioResultsCenter {
      _id
      asset {
        ... on BrazilianCompanyPortfolioAsset {
          name
          primaryLogo
          industrySegment
          tickerSymbolPrefix
        }
      }
      financialHighlights {
        _id
        fiscalPeriodDate
        fiscalPeriodQuarter
        highlights
      }
      netIncomeDiff {
        fiscalDate
        netIncome
      }
      latestFinancialsDeliveryDate
    }
  }
`;
