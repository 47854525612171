/* eslint-disable react/no-unstable-nested-components */
import FundamenteiBlack from "components/advertisements/FundamenteiBlack";
import TwoPassRender from "components/shared/TwoPassRender";
import uniq from "lodash/fp/uniq";
import type { ReactNode } from "react";
import { useState } from "react";

interface BlackPopUp {
  title: ReactNode;
  popUpContent: ReactNode;
  externalLinkText: string;
  externalLinkHref: string;
  rootClassName?: string;
  tag: string;
}

interface ActiveCampaignBlackPopUpsProps {
  activeCampaignContactTags: string[];
  popUps: BlackPopUp[];
}

const LS_BLACK_POPUPS_KEY = "@fundamentei/activeCampaign.blackPopUps";
export default function ActiveCampaignBlackPopUps({
  activeCampaignContactTags,
  popUps,
}: ActiveCampaignBlackPopUpsProps) {
  const [closedPopUps, setClosedPopUps] = useState<string[]>(() => {
    if (typeof localStorage !== "undefined") {
      const popUps = localStorage.getItem(LS_BLACK_POPUPS_KEY);
      if (!popUps) {
        return [];
      }
      try {
        return JSON.parse(popUps) || [];
      } catch {
        return [];
      }
    }
    return [];
  });

  const popUp = popUps.find(({ tag }) => {
    return activeCampaignContactTags.includes(tag);
  });

  if (!popUp) {
    return null;
  }

  const hasClosedPopUp = closedPopUps.includes(popUp.tag);
  return (
    <TwoPassRender>
      {() => {
        if (hasClosedPopUp) {
          return null;
        }
        return (
          <div key={popUp.tag} className="m-auto mb-0 mt-8 max-w-7xl space-y-8">
            <FundamenteiBlack
              popUpTitle={popUp.title}
              className={popUp.rootClassName}
              externalLinkText={popUp.externalLinkText}
              externalLinkHref={popUp.externalLinkHref}
              onClose={() => {
                setClosedPopUps((closedPopUps) => {
                  const nextClosedPopUps = uniq(closedPopUps.concat(popUp.tag));
                  localStorage.setItem(LS_BLACK_POPUPS_KEY, JSON.stringify(nextClosedPopUps));
                  return nextClosedPopUps;
                });
              }}
            >
              {popUp.popUpContent}
            </FundamenteiBlack>
          </div>
        );
      }}
    </TwoPassRender>
  );
}
