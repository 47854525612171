import classNames from "components/ui/classNames";
import getYear from "date-fns/getYear";
import { LinkAnchorButton } from "lib/miolo";
import Link from "next/link";
import * as React from "react";
import Fundamentei from "../branding/Fundamentei";

export default function Footer() {
  return (
    <footer className={classNames("mx-auto w-full max-w-screen-legacyDesktop px-8 xl:px-0")}>
      <div
        className={classNames(
          "grid justify-center gap-12 text-center lg:justify-between lg:text-left",
          "md:grid-cols-[repeat(3,max-content)]",
          "lg:grid-cols-[repeat(4,max-content)]",
          "xl:col-span-12 xl:grid-cols-[repeat(5,max-content)]",
          "grid-col-[100%]",
        )}
      >
        <div>
          <div className="grid gap-y-4">
            <h4 className="m-0 text-sm font-bold uppercase text-slate-600">Links</h4>

            <div>
              <ul className="m-0 grid list-none gap-y-4 p-0">
                <li>
                  <Link href="/premium" prefetch={false} legacyBehavior={true}>
                    <LinkAnchorButton href="/premium" className="text-sm font-medium tracking-wide text-slate-500">
                      Premium
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/fatos-relevantes" legacyBehavior={true}>
                    <LinkAnchorButton
                      href="/fatos-relevantes"
                      className="text-sm font-medium tracking-wide text-slate-500"
                    >
                      Fatos Relevantes
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/financials" legacyBehavior={true}>
                    <LinkAnchorButton href="/financials" className="text-sm font-medium tracking-wide text-slate-500">
                      Balanços
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/compare" legacyBehavior={true}>
                    <LinkAnchorButton href="/compare" className="text-sm font-medium tracking-wide text-slate-500">
                      Comparativo
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/irpf" legacyBehavior={true}>
                    <LinkAnchorButton href="/irpf" className="text-sm font-medium tracking-wide text-slate-500">
                      Imposto de Renda (IRPF)
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/events-calendar" legacyBehavior={true}>
                    <LinkAnchorButton
                      href="/events-calendar"
                      className="text-sm font-medium tracking-wide text-slate-500"
                    >
                      Agenda
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/sitemap?filterBy=BRAZILIAN_COMPANY" legacyBehavior={true}>
                    <LinkAnchorButton
                      href="/sitemap?filterBy=BRAZILIAN_COMPANY"
                      className="text-sm font-medium tracking-wide text-slate-500"
                    >
                      Top Ações
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/sitemap?filterBy=BRAZILIAN_REAL_ESTATE_FUND" legacyBehavior={true}>
                    <LinkAnchorButton
                      href="/sitemap?filterBy=BRAZILIAN_REAL_ESTATE_FUND"
                      className="text-sm font-medium tracking-wide text-slate-500"
                    >
                      Top Fundos Imobiliários
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/sitemap?filterBy=AMERICAN_COMPANY" legacyBehavior={true}>
                    <LinkAnchorButton
                      href="/sitemap?filterBy=AMERICAN_COMPANY"
                      className="text-sm font-medium tracking-wide text-slate-500"
                    >
                      Top Stocks
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/sitemap?filterBy=AMERICAN_REIT" legacyBehavior={true}>
                    <LinkAnchorButton
                      href="/sitemap?filterBy=AMERICAN_REIT"
                      className="text-sm font-medium tracking-wide text-slate-500"
                    >
                      Top REITs
                    </LinkAnchorButton>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <div className="grid gap-y-4">
            <h4 className="m-0 text-sm font-bold uppercase text-slate-600">A Empresa</h4>

            <div>
              <ul className="m-0 grid list-none gap-y-4 p-0">
                <li>
                  <LinkAnchorButton
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://help.fundamentei.com/pt-BR/collections/5579574-atualizacoes"
                    className="text-sm font-medium tracking-wide text-slate-500"
                  >
                    Novidades
                  </LinkAnchorButton>
                </li>

                <li>
                  <LinkAnchorButton
                    href="mailto:contato@fundamentei.com"
                    className="text-sm font-medium tracking-wide text-slate-500"
                  >
                    Contato
                  </LinkAnchorButton>
                </li>

                <li>
                  <Link href="/privacy" legacyBehavior={true}>
                    <LinkAnchorButton href="/privacy" className="text-sm font-medium tracking-wide text-slate-500">
                      Privacidade
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/payments-and-refunds" legacyBehavior={true}>
                    <LinkAnchorButton
                      href="/payments-and-refunds"
                      className="text-sm font-medium tracking-wide text-slate-500"
                    >
                      Pagamentos & reembolsos
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/terms" legacyBehavior={true}>
                    <LinkAnchorButton href="/terms" className="text-sm font-medium tracking-wide text-slate-500">
                      Termos de Uso
                    </LinkAnchorButton>
                  </Link>
                </li>

                <li>
                  <Link href="/anti-piracy" legacyBehavior={true}>
                    <LinkAnchorButton href="/anti-piracy" className="text-sm font-medium tracking-wide text-slate-500">
                      Política Anti-Pirataria
                    </LinkAnchorButton>
                  </Link>
                </li>
                <li>
                  <Link href="/anti-scraping" legacyBehavior={true}>
                    <LinkAnchorButton
                      href="/anti-scraping"
                      className="text-sm font-medium tracking-wide text-slate-500"
                    >
                      Política Anti-Scraping
                    </LinkAnchorButton>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <div className="grid gap-y-4">
            <h4 className="m-0 text-sm font-bold uppercase text-slate-600">Desenvolvedores</h4>

            <div>
              <ul className="m-0 grid list-none gap-y-4 p-0">
                <li>
                  <LinkAnchorButton
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://github.com/fundamentei"
                    className="text-sm font-medium tracking-wide text-slate-500"
                  >
                    GitHub
                  </LinkAnchorButton>
                </li>

                <li>
                  <LinkAnchorButton
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://stackshare.io/fundamentei/fundamentei"
                    className="text-sm font-medium tracking-wide text-slate-500"
                  >
                    StackShare
                  </LinkAnchorButton>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <div className="grid justify-center gap-y-4">
            <h4 className="m-0 text-sm font-bold uppercase text-slate-600">Nas Redes Sociais</h4>

            <div>
              <ul className="m-0 grid list-none grid-cols-[repeat(3,max-content)] gap-6 p-0 xl:grid-cols-[repeat(5,max-content)]">
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC72WcGU4HpVposjNHlP3FRg"
                    title="YouTube"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#829ab1] no-underline hover:text-[#E93323] focus:text-[#E93323] focus:outline-none"
                  >
                    <YouTube />
                  </a>
                </li>

                <li>
                  <a
                    href="https://instagram.com/fundamentei"
                    title="Instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#829ab1] no-underline hover:text-black focus:text-black focus:outline-none"
                  >
                    <Instagram />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/company/fundamentei"
                    title="LinkedIn"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#829ab1] no-underline hover:text-[#3376B0] focus:text-[#3376B0] focus:outline-none"
                  >
                    <LinkedIn />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:col-span-4 xl:col-span-1">
          <div className="grid grid-cols-1 gap-y-4 text-center">
            <Link
              href="/"
              aria-label="Página inicial"
              className="text-[#bfccda] hover:text-blue-600 focus:outline-none"
            >
              <Fundamentei fill="currentColor" width={39} height={37} />
            </Link>

            <div>
              <span className="text-sm font-medium tracking-wide text-[#829ab1]">
                © {getYear(new Date())} Fundamentei
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

function YouTube(props: React.SVGProps<any>) {
  return (
    <svg width={45} height={32} {...props}>
      <path
        fill="currentColor"
        d="M44.06 5.007c-.518-1.97-2.043-3.523-3.979-4.05C36.571 0 22.5 0 22.5 0S8.428 0 4.919.957c-1.936.527-3.461 2.08-3.979 4.05C0 8.579 0 16.032 0 16.032s0 7.453.94 11.026c.518 1.97 2.043 3.458 3.979 3.985C8.429 32 22.5 32 22.5 32s14.072 0 17.581-.957c1.936-.527 3.461-2.014 3.979-3.985.94-3.573.94-11.026.94-11.026s0-7.453-.94-11.025zM18 23V10l12 6.5L18 23z"
      />
    </svg>
  );
}

function Instagram(props: React.SVGProps<any>) {
  return (
    <svg width={32} height={32} {...props}>
      <path
        fill="currentColor"
        d="M16.004 7.796A8.19 8.19 0 007.8 16a8.19 8.19 0 008.203 8.204A8.19 8.19 0 0024.206 16a8.19 8.19 0 00-8.202-8.204zm0 13.538A5.343 5.343 0 0110.67 16a5.338 5.338 0 015.333-5.334A5.338 5.338 0 0121.336 16a5.343 5.343 0 01-5.332 5.334zM26.454 7.46a1.909 1.909 0 01-1.912 1.914 1.914 1.914 0 111.913-1.914zm5.434 1.942c-.122-2.563-.707-4.834-2.585-6.705C27.433.827 25.163.241 22.6.112c-2.641-.15-10.559-.15-13.2 0C6.844.234 4.574.82 2.697 2.69.819 4.561.24 6.832.112 9.395c-.15 2.642-.15 10.56 0 13.203.122 2.563.707 4.834 2.585 6.705 1.877 1.87 4.14 2.456 6.703 2.585 2.641.15 10.559.15 13.2 0 2.563-.122 4.833-.707 6.703-2.585 1.87-1.871 2.456-4.142 2.585-6.705.15-2.642.15-10.554 0-13.196zm-3.413 16.03a5.4 5.4 0 01-3.041 3.042c-2.106.836-7.103.643-9.43.643-2.328 0-7.332.186-9.43-.643a5.4 5.4 0 01-3.042-3.041c-.835-2.107-.643-7.105-.643-9.433 0-2.328-.185-7.333.643-9.433a5.4 5.4 0 013.041-3.041c2.106-.836 7.103-.643 9.43-.643 2.328 0 7.332-.186 9.431.643a5.4 5.4 0 013.041 3.041c.835 2.107.643 7.105.643 9.433 0 2.328.192 7.333-.643 9.433z"
      />
    </svg>
  );
}

function LinkedIn(props: React.SVGProps<any>) {
  return (
    <svg width={32} height={32} {...props}>
      <path
        fill="currentColor"
        d="M29.714 0H2.28C1.02 0 0 1.036 0 2.307v27.386C0 30.964 1.021 32 2.279 32h27.435C30.971 32 32 30.964 32 29.693V2.307C32 1.036 30.971 0 29.714 0zM9.671 27.429H4.93V12.157h4.75V27.43H9.67zM7.3 10.07a2.751 2.751 0 010-5.5 2.756 2.756 0 012.75 2.75 2.747 2.747 0 01-2.75 2.75zM27.45 27.43h-4.743V20c0-1.771-.036-4.05-2.464-4.05-2.472 0-2.85 1.929-2.85 3.921v7.558H12.65V12.157h4.55v2.086h.064c.636-1.2 2.186-2.464 4.493-2.464 4.8 0 5.693 3.164 5.693 7.278v8.372z"
        fillRule="nonzero"
      />
    </svg>
  );
}
