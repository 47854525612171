/* eslint-disable react/no-unstable-nested-components */
import ActiveCampaignBlackPopUps from "components/advertisements/ActiveCampaignBlackPopUps";
import CaixaPretaPopUps from "components/advertisements/CaixaPretaPopUps";
import FundamenteiBlackPerpetualPopUp from "components/advertisements/FundamenteiBlackPerpetualPopUp";
import FundamenteiBlackPopUps from "components/advertisements/FundamenteiBlackPopUps";
import type { IAuthContext } from "components/auth/Auth";
import { AuthContext } from "components/auth/Auth";
import OverdueSubscriptionStickyBanner from "components/billing/OverdueSubscriptionStickyBanner";
import Landing from "components/landing/Landing";
import IntlWrapper from "components/layouts/IntlWrapper";
import NavbarLayout from "components/layouts/NavbarLayout";
import BackToTop from "components/qol/BackToTop";
import Screener from "components/screener/Screener";
import { getInitialSearchStateFromQuery } from "components/screener/searchState";
import FundamenteiMetadata from "components/seo/FundamenteiMetadata";
import useChangeSetting from "components/settings/hooks/useChangeSetting";
import WholePageLoadingBlankslate from "components/shared/WholePageLoadingBlankslate";
import parseISO from "date-fns/parseISO";
import { SearchStateConsumer } from "lib/algolia/SearchStateContext";
import indexes from "lib/algolia/indexes";
import searchClient from "lib/algolia/searchClient";
import type { NextPage } from "next";
import { ThemeProvider } from "next-themes";
import { useRouter, useSearchParams } from "next/navigation";
import { useContext } from "react";
import type { InstantSearchProps } from "react-instantsearch-core";
import { findResultsState } from "react-instantsearch-dom/server";

interface IndexPageProps {
  indexName: string;
  resultsState?: any;
}

const Index: NextPage<IndexPageProps> = function Index({ indexName, resultsState }) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { value: hasClosedPerpetualBlackPopUp, set: setClosedPerpetualBlackPopUp } = useChangeSetting(
    "hasClosedMarch24PerpetualBlackPopUp",
    false,
  );
  const { user, loading } = useContext<IAuthContext>(AuthContext);
  if (loading) {
    return <WholePageLoadingBlankslate />;
  }

  if (!user) {
    return (
      <ThemeProvider forcedTheme="light" enableSystem={false} attribute="class" disableTransitionOnChange={true}>
        <Landing />
      </ThemeProvider>
    );
  }

  return (
    <NavbarLayout>
      <FundamenteiMetadata title="Fundamentei" />

      <BackToTop />

      <OverdueSubscriptionStickyBanner />

      {((user && user.viewerCanPurchaseBlack && !hasClosedPerpetualBlackPopUp) ||
        (user.isSiteAdmin && !hasClosedPerpetualBlackPopUp)) &&
        false && (
          <div className="m-auto mb-0 mt-8 max-w-7xl space-y-8">
            <FundamenteiBlackPerpetualPopUp
              externalLinkHref="https://black.fundamentei.com/black"
              onClose={() => setClosedPerpetualBlackPopUp(true)}
            >
              <p>
                Fala Investidor! Para você que frequenta o Fundamentei há tanto tempo, estamos disponibilizando uma
                oferta especial por tempo limitado no Black: <strong>R$200</strong> de desconto utilizando o cupom{" "}
                <span className="rounded-xl bg-black/30 px-2 py-1 font-black text-orange-300">FUND200</span>. Clique e
                confira!
              </p>
            </FundamenteiBlackPerpetualPopUp>
          </div>
        )}

      <CaixaPretaPopUps
        currentDate={new Date()}
        popUps={[
          {
            key: "liveADiversificaçãoPerfeita1",
            title: (
              <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                <span>Semana da Carteira Perfeita | Aula 1 Liberada ✅</span>
                <span className="relative flex h-2 w-2 md:h-3 md:w-3">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-80" />
                  <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500 md:h-3 md:w-3" />
                </span>
              </h2>
            ),
            popUpContent: (
              <p>
                Fala Investidor! Economize milhares de reais na Bolsa aprendendo nessa aula a evitar todos os erros que
                cometi desde que comecei a investir com as minhas notas de corretagens reais.
              </p>
            ),
            externalLinkText: "Assistir",
            externalLinkHref: "https://caixapreta.fundamentei.com/aula-1-cmmc",
            period: [parseISO("2024-03-11T08:00:00"), parseISO("2024-03-12T07:59:00")],
            rootClassName: "bg-gradient-to-r from-black/70 to-orange-400/100",
          },
          {
            key: "liveADiversificaçãoPerfeita2",
            title: (
              <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                <span>Semana da Carteira Perfeita | Aula 2 Liberada ✅</span>
                <span className="relative flex h-2 w-2 md:h-3 md:w-3">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-80" />
                  <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500 md:h-3 md:w-3" />
                </span>
              </h2>
            ),
            popUpContent: (
              <p>
                Fala Investidor! Já ouviu falar sobre o conceito da Diversificação Perfeita? Então veja agora como você
                pode aplicar na sua carteira e praticamente zerar o seu risco na Bolsa.
              </p>
            ),
            externalLinkText: "Assistir",
            externalLinkHref: "https://caixapreta.fundamentei.com/aula-2-adivperf",
            period: [parseISO("2024-03-12T08:00:00"), parseISO("2024-03-13T07:59:00")],
            rootClassName: "bg-gradient-to-r from-black/70 to-orange-400/100",
          },
          {
            key: "caixaPretaInscriçõesAbertas",
            title: (
              <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                <span>Caixa Preta | Inscrições Abertas</span>
                <span className="inline-flex animate-pulse">🔥</span>
              </h2>
            ),
            popUpContent: (
              <p>
                Quer descobrir cada Ação, FII, Stock e REIT que compõe a minha carteira? Então garanta já a sua vaga no
                Caixa Preta, o melhor curso que já criei até hoje!
              </p>
            ),
            externalLinkText: "Ver detalhes",
            externalLinkHref: "https://hotm.art/YJqEjC6V",
            period: [parseISO("2024-03-13T08:00:00"), parseISO("2024-03-15T23:59:00")],
            rootClassName: "bg-gradient-to-r from-black/70 to-red-500/100",
          },
          {
            key: "caixaPretaÚltimosDias",
            title: (
              <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                <span>Caixa Preta | Últimas Horas</span>
                <span className="inline-flex animate-pulse">⚠️</span>
              </h2>
            ),
            popUpContent: (
              <p>
                Últimas horas para você descobrir cada ação, FII, Stock e REIT que compõe a minha carteira de renda
                variável! Descubra agora quais ativos nós temos em comum.
              </p>
            ),
            externalLinkText: "Garantir minha vaga!",
            externalLinkHref: "https://hotm.art/YJqEjC6V",
            period: [parseISO("2024-03-16T00:00:00"), parseISO("2024-03-17T23:59:00")],
            rootClassName: "bg-gradient-to-r from-black/70 to-red-500/100",
          },
        ]}
      />

      <FundamenteiBlackPopUps
        currentDate={new Date()}
        popUps={[
          // Semana do Buy&Hold | Aula 1 Liberada
          {
            key: "fundamenteiBlackAd202401_1",
            title: (
              <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                <span>Semana do Buy&Hold | Aula 1 Liberada</span>
                <span className="inline-flex animate-pulse">✅</span>
              </h2>
            ),
            popUpContent: (
              <p>
                Assista agora a melhor aula sobre o método Buy&Hold com Ações que eu já fiz! Sua mentalidade de
                investidor vai mudar para sempre, eu garanto...
              </p>
            ),
            externalLinkText: "Assistir",
            externalLinkHref: "https://black.fundamentei.com/acoes",
            period: [parseISO("2024-01-15T08:00:00"), parseISO("2024-01-16T07:59:00")],
            rootClassName: "bg-gradient-to-r from-black/100 to-teal-900/100",
          },

          // Semana do Buy&Hold | Aula 2 Liberada
          {
            key: "fundamenteiBlackAd202401_2",
            title: (
              <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                <span>Semana do Buy&Hold | Aula 2 Liberada</span>
                <span className="inline-flex animate-pulse">✅</span>
              </h2>
            ),
            popUpContent: (
              <p>
                Ainda está na dúvida se deve investir ou não em Fundos Imobiliários? Descubra agora se realmente vale a
                pena na nossa segunda aula!
              </p>
            ),
            externalLinkText: "Assistir",
            externalLinkHref: "https://black.fundamentei.com/fiis-bhinv",
            period: [parseISO("2024-01-16T08:00:00"), parseISO("2024-01-17T07:59:00")],
            rootClassName: "bg-gradient-to-r from-black/100 to-teal-900/100",
          },

          // Semana do Buy&Hold | Aula 3 Liberada
          {
            key: "fundamenteiBlackAd202401_3",
            title: (
              <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                <span>Semana do Buy&Hold | Aula 3 Liberada</span>
                <span className="inline-flex animate-pulse">✅</span>
              </h2>
            ),
            popUpContent: (
              <p>
                Por que será que mais de 1 milhão de brasileiros investem no exterior em Stocks e REITs? Veja na nossa
                terceira aula os principais riscos de manter todo o seu patrimônio Brasil!
              </p>
            ),
            externalLinkText: "Assistir",
            externalLinkHref: "https://black.fundamentei.com/exterior-bhusd",
            period: [parseISO("2024-01-17T08:00:00"), parseISO("2024-01-18T07:59:59")],
            rootClassName: "bg-gradient-to-r from-black/100 to-teal-900/100",
          },

          // Fundamentei Black | Inscrições Abertas
          {
            key: "fundamenteiBlackAd202401_4",
            title: (
              <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                <span>Fundamentei Black | Inscrições Abertas</span>
                <span className="inline-flex animate-pulse">🔥</span>
              </h2>
            ),
            popUpContent: (
              <p>
                Estão abertas as inscrições para a melhor área de ensino sobre Buy&Hold do Brasil! Aprenda tudo sobre
                Ações, Fundos Imobiliários, Stocks e REITs num único lugar e nunca mais se preocupe em perder dinheiro
                na Bolsa.
              </p>
            ),
            externalLinkText: "Ver detalhes",
            externalLinkHref: "https://hotm.art/HqMWAP0",
            period: [parseISO("2024-01-18T08:00:00"), parseISO("2024-01-19T23:59:59")],
            rootClassName: "bg-gradient-to-r from-black/100 to-stone-500/100",
          },

          // Fundamentei Black | Últimas Horas
          {
            key: "fundamenteiBlackAd202401_5",
            title: (
              <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                <span>Fundamentei Black | Últimas Horas</span>
                <span className="inline-flex animate-pulse">🔥</span>
              </h2>
            ),
            popUpContent: (
              <p>
                Últimas horas para você se inscrever na melhor área de ensino sobre Buy&Hold do Brasil! Conte com um
                suporte pessoal e humanizado para tirar todas as suas dúvidas e aumente a segurança na escolha dos seus
                ativos.
              </p>
            ),
            externalLinkText: "Ver detalhes",
            externalLinkHref: "https://hotm.art/HqMWAP0",
            period: [parseISO("2024-01-20T00:00:00"), parseISO("2024-01-21T23:59:00")],
            rootClassName: "bg-gradient-to-r from-black/100 to-stone-500/100",
          },
        ]}
      />

      {!user.isBlack && user.activeCampaignContactTags.length > 0 && (
        <ActiveCampaignBlackPopUps
          activeCampaignContactTags={user.activeCampaignContactTags}
          popUps={[
            // Semana do Buy&Hold | Aula 1 Liberada
            {
              tag: "Black - CPL 1",
              title: (
                <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                  <span>Semana do Buy&Hold | Aula 1 Liberada</span>
                  <span className="inline-flex animate-pulse">✅</span>
                </h2>
              ),
              popUpContent: (
                <p>
                  Assista agora a melhor aula sobre o método Buy&Hold com Ações que eu já fiz! Sua mentalidade de
                  investidor vai mudar para sempre, eu garanto...
                </p>
              ),
              externalLinkText: "Assistir",
              externalLinkHref: "https://dfl0.us/s/3cda2dfb",
              rootClassName: "bg-gradient-to-r from-black/100 to-teal-900/100",
            },

            // Semana do Buy&Hold | Aula 2 Liberada
            {
              tag: "Black - CPL 2",
              title: (
                <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                  <span>Semana do Buy&Hold | Aula 2 Liberada</span>
                  <span className="inline-flex animate-pulse">✅</span>
                </h2>
              ),
              popUpContent: (
                <p>
                  Ainda está na dúvida se deve investir ou não em Fundos Imobiliários? Descubra agora se realmente vale
                  a pena na nossa segunda aula!
                </p>
              ),
              externalLinkText: "Assistir",
              externalLinkHref: "https://dfl0.us/s/1376e3b8",
              rootClassName: "bg-gradient-to-r from-black/100 to-teal-900/100",
            },

            // Semana do Buy&Hold | Aula 3 Liberada
            {
              tag: "Black - CPL 3",
              title: (
                <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                  <span>Semana do Buy&Hold | Aula 3 Liberada</span>
                  <span className="inline-flex animate-pulse">✅</span>
                </h2>
              ),
              popUpContent: (
                <p>
                  Por que será que mais de 1 milhão de brasileiros investem no exterior em Stocks e REITs? Veja na nossa
                  terceira aula os principais riscos de manter todo o seu patrimônio Brasil!
                </p>
              ),
              externalLinkText: "Assistir",
              externalLinkHref: "https://dfl0.us/s/fbcd6d2d",
              rootClassName: "bg-gradient-to-r from-black/100 to-teal-900/100",
            },

            // Fundamentei Black | Inscrições Abertas
            {
              tag: "Black - Inscrições Abertas",
              title: (
                <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                  <span>Fundamentei Black | Inscrições Abertas</span>
                  <span className="inline-flex animate-pulse">🔥</span>
                </h2>
              ),
              popUpContent: (
                <p>
                  Estão abertas as inscrições para a melhor área de ensino sobre Buy&Hold do Brasil! Aprenda tudo sobre
                  Ações, Fundos Imobiliários, Stocks e REITs num único lugar e nunca mais se preocupe em perder dinheiro
                  na Bolsa.
                </p>
              ),
              externalLinkText: "Ver detalhes",
              externalLinkHref: "https://black.fundamentei.com/black",
              rootClassName: "bg-gradient-to-r from-black/100 to-stone-500/100",
            },

            // Fundamentei Black | Black - Últimos Dias
            {
              tag: "Black - Últimos Dias",
              title: (
                <h2 className="inline-flex items-center space-x-2 rounded-md bg-black/20 p-1 px-2 font-bold leading-6 text-white md:text-xl md:leading-normal">
                  <span>Fundamentei Black | Últimas Horas</span>
                  <span className="inline-flex animate-pulse">🔥</span>
                </h2>
              ),
              popUpContent: (
                <p>
                  Últimas horas para você se inscrever na melhor área de ensino sobre Buy&Hold do Brasil! Conte com um
                  suporte pessoal e humanizado para tirar todas as suas dúvidas e aumente a segurança na escolha dos
                  seus ativos.
                </p>
              ),
              externalLinkText: "Ver detalhes",
              externalLinkHref: "https://black.fundamentei.com/black",
              rootClassName: "bg-gradient-to-r from-black/100 to-stone-500/100",
            },
          ]}
        />
      )}

      <SearchStateConsumer>
        {({ searchState, setSearchState }) => (
          <Screener
            searchClient={searchClient}
            indexName={indexName}
            searchState={searchState}
            resultsState={resultsState}
            onSearchStateChange={setSearchState}
            searchParams={searchParams}
            router={router}
          />
        )}
      </SearchStateConsumer>
    </NavbarLayout>
  );
};

interface ScreenerWithIntlProxyProps extends InstantSearchProps {}
function ScreenerWithIntlProxy({ ...props }: ScreenerWithIntlProxyProps) {
  // We need to proxy the <IntlWrapper> through the <Screener> component since we won't get a render from the `_app`
  // when using `findResultsState` in `getInitialProps`
  return (
    <IntlWrapper
      locale={{
        id: "pt-BR",
        messages: {},
      }}
    >
      <Screener {...props} />
    </IntlWrapper>
  );
}

Index.getInitialProps = async ({ query }) => {
  const indexName = indexes.issuers;
  const searchState = getInitialSearchStateFromQuery(query);

  const resultsState = await findResultsState(ScreenerWithIntlProxy, {
    searchClient,
    indexName,
    searchState,
  });

  return {
    indexName,
    resultsState,
  };
};

export default Index;
